import React, { useState } from "react"
import {
    Card,
    CardHeader,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
} from "reactstrap"
import classnames from "classnames"

//i18n
import { withTranslation } from "react-i18next"

import Info from "./Info"
import Config from "./Config"
import Dcu from "./Dcu"

const DeviceInfo = ({ deviceId, deviceOgrId, deviceData, t, presets }: any) => {
    const [customActiveTab, setCustomActiveTab] = useState("1")

    const toggleCustom = (tab: any) => {
        if (customActiveTab !== tab) {
            setCustomActiveTab(tab)
        }
    }

    if (deviceId != null) {
        return (
            <>
                <Card>
                    <CardHeader>
                        <strong>
                            {t("Device")} {deviceOgrId}
                        </strong>
                    </CardHeader>
                </Card>
                <Nav tabs className="nav-tabs-custom nav-justified">
                    <NavItem>
                        <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                                active: customActiveTab === "1",
                            })}
                            onClick={() => {
                                toggleCustom("1")
                            }}
                        >
                            <span className="d-block d-sm-none">
                                <i className="fas fa-home"></i>
                            </span>
                            <span className="d-none d-sm-block">
                                {t("Info")}
                            </span>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                                active: customActiveTab === "3",
                            })}
                            onClick={() => {
                                toggleCustom("3")
                            }}
                        >
                            <span className="d-block d-sm-none">
                                <i className="far fa-envelope"></i>
                            </span>
                            <span className="d-none d-sm-block">
                                {t("Dimming Curve")}
                            </span>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                                active: customActiveTab === "2",
                            })}
                            onClick={() => {
                                toggleCustom("2")
                            }}
                        >
                            <span className="d-block d-sm-none">
                                <i className="far fa-user"></i>
                            </span>
                            <span className="d-none d-sm-block">
                                {t("Config")}
                            </span>
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent
                    activeTab={customActiveTab}
                    className="p-3 text-muted"
                >
                    <TabPane tabId="1">
                        <Info
                            t={t}
                            deviceData={deviceData}
                            deviceId={deviceId}
                        />
                    </TabPane>
                    <TabPane tabId="2">
                        <Config t={t} deviceId={deviceId} />
                    </TabPane>
                    <TabPane tabId="3">
                        <Dcu t={t} deviceId={deviceId} presets={presets} />
                    </TabPane>
                </TabContent>
            </>
        )
    }

    return <>{t("NoDeviceInfo")}</>
}

export default withTranslation()(DeviceInfo)
